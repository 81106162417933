import { PaginationProps, TableProps } from "antd";
import { useCallback, useState } from "react";

import { IPaginatedResponseBase, IResponseBase } from "../request";
import {
  UseConditionsQuery,
  UseConditionsQueryOptions,
  UseConditionsQueryWithValidatorOptions,
  useConditionsQuery,
} from "./useConditionsQuery";

export const useConditionsQueryAntTableConfig = {
  defaultPageSize: 10,
  query: (
    params: { pageSize: number; pageNo: number },
    query: (params: any) => Promise<IPaginatedResponseBase<any> | IResponseBase<any[]>>,
  ) =>
    query(params).then((res) => {
      if (res.success === 0) {
        if (Array.isArray(res.data)) {
          return { list: res.data, total: res.data.length };
        }
        return res.data;
      }

      return Promise.reject();
    }),
};

export type AvailablePaginatedResponse<T> = Promise<IPaginatedResponseBase<T> | IResponseBase<T[]>>;

export type UseConditionsQueryAntTableOptions<Conditions extends {}, Parameters extends {}, ListDataItem> = Mutation<
  UseConditionsQueryOptions<Conditions, Parameters & { pageSize?: number; pageNo?: number }>,
  {
    query: (params: Conditions & Parameters) => AvailablePaginatedResponse<ListDataItem>;
  }
>;

export type UseConditionsQueryAntTableWithValidatorOptions<
  Conditions extends {},
  Parameters extends {},
  FormatterData,
  ListDataItem,
> = Mutation<
  UseConditionsQueryWithValidatorOptions<Conditions, Parameters & { pageSize: number; pageNo: number }, FormatterData>,
  {
    query: (
      params: FormatterData extends void
        ? Conditions & Parameters & { pageSize: number; pageNo: number }
        : FormatterData,
    ) => AvailablePaginatedResponse<ListDataItem>;
  }
>;

export type UseConditionsQueryAntTable<Conditions extends {}, Parameters extends {}, FormatterData, ListDataItem> = {
  setData: React.Dispatch<
    React.SetStateAction<{
      list: ListDataItem[];
      total: number;
    }>
  >;
  tableProps: {
    dataSource: ListDataItem[];
    loading: boolean;
  };
} & UseConditionsQuery<Conditions, Parameters, FormatterData>;

export function useConditionsQueryAntTable<Conditions extends {}, Parameters extends {}, FormatterData, ListDataItem>(
  options: UseConditionsQueryAntTableWithValidatorOptions<Conditions, Parameters, FormatterData, ListDataItem>,
): UseConditionsQueryAntTable<Conditions, Parameters, FormatterData, ListDataItem>;
export function useConditionsQueryAntTable<Conditions, Parameters, ListDataItem>(
  options: UseConditionsQueryAntTableOptions<Conditions, Parameters, ListDataItem>,
): UseConditionsQueryAntTable<Conditions, Parameters, unknown, ListDataItem>;
export function useConditionsQueryAntTable<
  Conditions extends {},
  Parameters extends { pageNo?: number; pageSize?: number },
  FormatterData,
  ListDataItem,
>(
  options:
    | UseConditionsQueryAntTableOptions<Conditions, Parameters, ListDataItem>
    | UseConditionsQueryAntTableWithValidatorOptions<Conditions, Parameters, FormatterData, ListDataItem>,
) {
  const [data, setData] = useState({
    list: [] as ListDataItem[],
    total: 0,
  });

  // @ts-ignore
  const conditionsQuery = useConditionsQuery({
    ...options,
    params: { pageSize: useConditionsQueryAntTableConfig.defaultPageSize, pageNo: 1, ...options.params },
    // @ts-ignore
    query: (params) =>
      useConditionsQueryAntTableConfig.query(params, options.query).then((res) => {
        setData(res);
      }),
  });

  function query() {
    conditionsQuery.setParams({ pageNo: 1 });
    conditionsQuery.query();
  }

  //
  const pagination: PaginationProps = {
    current: conditionsQuery.params.pageNo,
    pageSize: conditionsQuery.params.pageSize,
    size: "small",
    total: data.total,
    showTotal: (total, range) => `共 ${total} 条记录`,
    onChange: (page: number, pageSize?: number) => {
      // 单页条数变化时，应当回到第一页
      conditionsQuery.setParams({
        pageNo: conditionsQuery.params.pageSize === pageSize ? page : 1,
        pageSize,
      } as Partial<Parameters>);
      if (options.pagination !== "local" && options.pagination !== false) {
        conditionsQuery.fetch();
      }
    },
  };
  const tableProps = {
    dataSource: data.list,
    loading: conditionsQuery.fetching,
  };

  if (options.pagination === false) {
    // @ts-expect-error
    tableProps.pagination = false;
  } else {
    // @ts-ignore
    tableProps.pagination = pagination;
  }

  return {
    ...conditionsQuery,
    query,
    setData,
    tableProps,
  };
}
