import { ConfigProvider } from "antd";
import "antd/dist/antd.min.css";
import zhCN from "antd/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/dist/locale/zh-cn";

import "@/utils/install";
import "@/stores/install";

import "./App.less";

import Pages from "@/pages";

moment.locale("zh-cn");

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <div className="App">
        <Pages />
      </div>
    </ConfigProvider>
  );
}

export default App;
