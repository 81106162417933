import { Modal, message } from "antd";
import { AxiosError } from "axios";
import qs from "qs";
import { omit } from "underscore";

import config from "@/config/config";
import authorizeStore from "@/stores/authorizeStore";

import { axiosInstance } from ".";
import { ICustomRequestConfig, IResponseBase } from "./interfaces";

let isUnAuthorizeDialogShowing = false;
function handleUnauthorize(msg: string) {
  if (isUnAuthorizeDialogShowing) return;
  isUnAuthorizeDialogShowing = true;
  Modal.confirm({
    content: msg,
    cancelText: "留在当前页",
    okText: "返回登录页",
    onOk: () => {
      isUnAuthorizeDialogShowing = false;
      // 移除用户信息返回至登录页
      window.location.hash = "/login";
    },
  });
}

axiosInstance.interceptors.request.use((config: ICustomRequestConfig) => {
  // 如果token存在将其加入 headers
  if (!config.allowAnonymity && authorizeStore.token) {
    if (!config.headers) {
      config.headers = {};
    }
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${authorizeStore.token}`;
  }

  if (config.params && config.params.pageNo) {
    config.params = { ...omit(config.params, "pageNo"), pageNum: config.params.pageNo };
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const { data } = response as { data: { code: number; msg: string } };
    const { config } = response as { config: ICustomRequestConfig };

    let businessData = omit(data, ["code", "msg"]);

    if (data.code === 200) {
      if (config.desc && config.showMessage) {
        message.success(`${config.desc}成功`);
      }
      if (typeof businessData === "object" && businessData !== null && "rows" in businessData) {
        // @ts-ignore
        businessData.list = [...businessData.rows];
      }
      if (typeof config?.formatter === "function") {
        businessData = config.formatter(businessData);
      }
    } else if (data.code === 401) {
      handleUnauthorize("登录状态已过期");
    } else if (config.desc && config.showMessage) {
      message.error(data.msg || `${config.desc}失败`);
    }

    response.data = {
      success: data.code === 200 ? 0 : data.code,
      message: data.msg,
      data: businessData,
    };

    return response;
  },
  (error) => {
    if (error instanceof Error) {
      if ("response" in (error as AxiosError)) {
        const ex = error as AxiosError;
        if (ex.response?.status === 401) {
          authorizeStore.clean();
          handleUnauthorize(ex.response.data as any);
        }
      }
    }
  }
);

axiosInstance.defaults.headers = {
  // @ts-expect-error
  "Content-Type": "application/json",
};

axiosInstance.defaults.baseURL = config.baseURL;
axiosInstance.defaults.timeout = 120 * 1000;
axiosInstance.defaults.paramsSerializer = (params) => qs.stringify(params);
