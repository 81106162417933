import { message } from "antd";
import axios from "axios";
import type { AxiosError, Method } from "axios";
import qs from "qs";

import { ICustomRequestConfig, IPaginatedResponseBase, IPaginatedResponseBaseData, IResponseBase } from "./interfaces";

export type {
  ICustomRequestConfig,
  IPaginatedResponseBase,
  IPaginatedResponseBaseData,
  IResponseBase,
} from "./interfaces";

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  transformRequest(data, headers) {
    switch (headers!["Content-Type"]) {
      case "application/x-www-form-urlencoded":
        return qs.stringify(data);
      case "application/json":
        return JSON.stringify(data);
      case "multipart/form-data":
        return data;
      default:
        return qs.stringify(data);
    }
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error instanceof Error) {
      if ("response" in (error as AxiosError)) {
        const ex = error as AxiosError;
        if (ex.response?.status) {
          let msg = "";
          switch (ex.response.status) {
            case 400:
              msg = "请求错误";
              break;

            case 401:
              msg = "未授权，请登录";
              break;

            case 403:
              msg = "拒绝访问";

              break;
            case 404:
              msg = `请求地址不存在: ${ex.response.config.url}`;
              break;

            case 408:
              msg = "请求超时";
              break;

            case 500:
              msg = "服务器内部错误";
              break;

            case 501:
              msg = "服务未实现";
              break;

            case 502:
              msg = "网关错误";
              break;

            case 503:
              msg = "服务不可用";
              break;

            case 504:
              msg = "网关超时";
              break;

            case 505:
              msg = "HTTP版本不受支持";
              break;

            default:
              msg = "请求错误";
              break;
          }

          message.error(`${ex.response.status}：${msg}`);
        } else if (ex.message === "Network Error") {
          message.error("网络错误，请检查本地网络");
        } else if (ex.code === "ECONNABORTED") {
          message.error("连接超时，请稍后再试");
        }
      }
    }

    return Promise.reject(error);
  }
);

export async function request<T = any>(url: string, options?: ICustomRequestConfig): Promise<IResponseBase<T>>;
export async function request<T = any>(
  url: string,
  method: Method,
  params: {} | null,
  options?: ICustomRequestConfig
): Promise<IResponseBase<T>>;
export async function request(
  url: string,
  params2?: Method | ICustomRequestConfig,
  params3?: {} | null,
  params4?: ICustomRequestConfig
) {
  const options: ICustomRequestConfig = typeof params2 === "object" ? params2 : params4 || {};

  const $options: ICustomRequestConfig = { url, showMessage: true };

  if (typeof params2 === "string") {
    $options.method = params2;
    if (params2 === "GET" && !$options.params) {
      $options.params = params3;
    } else if (params2 === "POST" && !$options.data) {
      $options.data = params3;
    }
  }

  const res = await axiosInstance.request({ ...$options, ...options });

  return res.data;
}

export async function post<T = any>(
  url: string,
  params: {} | null,
  options?: ICustomRequestConfig
): Promise<IResponseBase<T>>;
export async function post<T, R>(
  url: string,
  params: {} | null,
  options: Mutation<
    ICustomRequestConfig,
    {
      formatter: (data: T) => R;
    }
  >
): Promise<IResponseBase<R>>;
export async function post<T = any>(url: string, params: {} | null, options?: ICustomRequestConfig) {
  const $options = { method: "post", data: params, ...options };

  return request<T>(url, $options);
}

export async function get<T = any>(url: string, params: {} | null, options?: ICustomRequestConfig) {
  const $options = { method: "GET", params, ...options };

  return request<T>(url, "GET", params, $options);
}

export async function put<T = any>(
  url: string,
  params: {} | null,
  options?: ICustomRequestConfig
): Promise<IResponseBase<T>>;
export async function put<T, R>(
  url: string,
  params: {} | null,
  options: Mutation<
    ICustomRequestConfig,
    {
      formatter: (data: T) => R;
    }
  >
): Promise<IResponseBase<R>>;
export async function put<T = any>(url: string, params: {} | null, options?: ICustomRequestConfig) {
  const $options = { method: "put", data: params, ...options };

  return request<T>(url, $options);
}

export async function remove<T = any>(url: string, params: {} | null, options?: ICustomRequestConfig) {
  const $options = { method: "DELETE", params, ...options };

  return request<T>(url, "DELETE", params, $options);
}

// 兼容部分旧代码
request.post = post;

request.get = get;

request.put = put;

request.remove = remove;

export const methods = { GET: "GET" as const, POST: "POST" as const };

export default request;

/**
 * 格式化列表数据
 * @param data
 * @returns
 */
export function listDataFormatter<T = any>(data: any): IPaginatedResponseBaseData<T> {
  if (!data)
    return {
      list: [],
      total: 0,
    };

  if (Array.isArray(data)) {
    return {
      list: data,
      total: data.length,
    };
  }

  return {
    ...data,
    total: Number(data.length),
  };
}
