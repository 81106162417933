import React, { useEffect, useState } from "react";

import AsyncComponentSpin from "./AsyncComponentSpin";

const components = new Map();

export default function AsyncComponent<T extends React.ComponentClass | React.FunctionComponent>(props: {
  component: () => Promise<{ default: T }>;
  placeholder?: JSX.Element;
}) {
  const { component, placeholder = <AsyncComponentSpin />, ...comProps } = props;
  // 直接把组件函数当做参数useState时将被执行
  const [Com, setCom] = useState<any>(() => components.get(component));

  useEffect(() => {
    if (Com) {
      return;
    }
    component().then((res) => {
      if (!res.default) {
        console.error(`AsyncComponent：${component.name}，没有默认导出`);
      }
      components.set(component, res.default);
      setCom(() => res.default);
    });
  }, [Com, component]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return Com ? <Com {...comProps} /> : placeholder;
}
