import { ICustomRequestConfig, request } from "@/utils/request";

const PRE = ``;

class IndexApi {
  /**
   * 登录
   * @param params
   * @param config
   * @returns
   */
  login(params: { username: string; password: string; code: string; uuid: string }, config?: ICustomRequestConfig) {
    return request.post<Login>(`${PRE}/login`, params, { desc: "登录", ...config, allowAnonymity: true });
  }

  /**
   * 验证码
   * @param params
   * @param config
   * @returns
   */
  captchaImage(params: null, config?: ICustomRequestConfig) {
    return request.get<CaptchaImage>(`${PRE}/captchaImage`, params, { ...config, allowAnonymity: true });
  }

  /**
   * 获取用户信息
   * @param params
   * @param config
   * @returns
   */
  getInfo(params: null, config?: ICustomRequestConfig) {
    return request.get<GetInfo>(`${PRE}/getInfo`, params, { desc: "获取用户信息", ...config });
  }

  /**
   * 读取路由配置
   * @param params
   * @param config
   * @returns
   */
  getRouters(params: null, config?: ICustomRequestConfig) {
    return request.get<GetRouters>(`${PRE}/getRouters`, params, { ...config });
  }
}

export type Login = {
  token: string;
};

export type CaptchaImage = {
  captchaEnabled: boolean;
  img: string;
  uuid: string;
};

export type GetInfo = {
  permissions: string[];
  roles: string[];
  user: {
    searchValue: null;
    createBy: string;
    createTime: Date;
    updateBy: null;
    updateTime: null;
    remark: string;
    params: {
      "@type": string;
    };
    userId: number;
    deptId: number;
    userName: string;
    nickName: string;
    email: string;
    phonenumber: string;
    sex: string;
    avatar: string;
    password: string;
    status: string;
    delFlag: string;
    loginIp: string;
    loginDate: Date;
    dept: {
      searchValue: null;
      createBy: null;
      createTime: null;
      updateBy: null;
      updateTime: null;
      remark: null;
      params: {
        "@type": string;
      };
      deptId: number;
      parentId: number;
      ancestors: string;
      deptName: string;
      orderNum: number;
      leader: string;
      phone: null;
      email: null;
      status: string;
      delFlag: null;
      parentName: null;
      children: any[];
    };
    roles: {
      searchValue: null;
      createBy: null;
      createTime: null;
      updateBy: null;
      updateTime: null;
      remark: null;
      params: {
        "@type": string;
      };
      roleId: number;
      roleName: string;
      roleKey: string;
      roleSort: string;
      dataScope: string;
      menuCheckStrictly: boolean;
      deptCheckStrictly: boolean;
      status: string;
      delFlag: null;
      flag: boolean;
      menuIds: null;
      deptIds: null;
      permissions: null;
      admin: boolean;
    }[];
    roleIds: null;
    postIds: null;
    roleId: null;
    admin: boolean;
  };
};

export type GetRouters = {
  data: TreeLike<{
    component: string;
    name: string;
    meta?: { title: string };
    path: string;
  }>;
};

export const indexApi = new IndexApi();

export default indexApi;
