import { makeObservable, observable, runInAction } from "mobx";
import _ from "underscore";

import indexApi from "@/api/system/index.api";
import { RouterConfig, inLayoutPages, publicPages } from "@/pages/routers";
import * as treeLike from "@/utils/tree-like";

import authorizeStore from "./authorizeStore";

class PermissionStore {
  pages: RouterConfig[] = [...publicPages];

  inLayoutPages: RouterConfig[] = [];

  permissions: string[] = [];

  fetching = false;

  fetched = false;

  constructor() {
    makeObservable(this, {
      pages: observable,
      permissions: observable,
      fetching: observable,
      fetched: observable,
    });
    if (authorizeStore.isAuthorized) {
      this.restore();
    }
  }

  async fetch() {
    this.fetching = true;
    return indexApi
      .getRouters(null)
      .then((res) => {
        // 要处理下若依返回的数据
        if (res.success !== 0) {
          return;
        }
        // 后面四个路由配置不要
        const routers = res.data.data.slice(4, res.data.data.length).map((i, index) => {
          const router = !i.meta ? i.children![0] : i;
          return {
            id: router.name,
            title: router.meta!.title,
            path: router.path,
            component: !i.meta ? router.component : undefined,
            meta: {
              index: index === 0,
            },
            children:
              i.children && i.meta
                ? i.children.map((ii, iIndex) => ({
                    id: ii.name,
                    title: ii.meta!.title,
                    path: ii.path,
                    component: ii.component,
                    meta: {
                      index: iIndex === 0,
                    },
                  }))
                : [],
          };
        });

        routers.forEach((i) => {
          i.path = i.path!.replace(/^\//, "");
        });

        const pages = [...publicPages];
        const layout = pages.find((i) => i.id === "layout")!;
        layout.children = [...routers];
        this.inLayoutPages = [...routers];
        this.pages = pages;
      })
      .finally(() => {
        this.fetching = false;
        this.fetched = true;
      });
  }

  // async fetch() {
  //   const pages = [...publicPages];
  //   const layout = pages.find((i) => i.id === "layout");

  //   layout!.children = [...inLayoutPages];

  //   this.inLayoutPages = layout!.children;
  //   this.pages = [...pages];
  // }

  private storeKey = "store.permissions";

  private restore() {
    const json = localStorage.getItem(this.storeKey);

    this.install(json ? JSON.parse(json) : []);
  }

  install(data?: string[]) {
    if (!data) return;
    localStorage.setItem(this.storeKey, JSON.stringify(data));

    this.permissions = data;

    this.fetch();

    // this.pages = treeLike.filter(
    //   inLayoutPages,
    //   (node) => {
    //     const meta = { index: false, allowAnonymous: false, ...(node.meta || {}) };
    //     const authorities: string[] = Array.isArray(node.authorities)
    //       ? node.authorities
    //       : typeof node.authorities === "string"
    //       ? [node.authorities]
    //       : [];
    //     return meta.allowAnonymous || authorities.every((authority) => data!.includes(authority));
    //   },
    //   { flat: false }
    // );
  }

  clean() {
    if (this.permissions.length) {
      this.permissions = [];
      this.pages = [];
      this.fetching = false;
    }
  }
}

export const permissionStore = new PermissionStore();

export default permissionStore;
