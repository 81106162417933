import React, { useState } from "react";

import AsyncComponentSpin from "@/components/AsyncComponent/AsyncComponentSpin";

export default function Iframe(props: { title: string; style?: React.CSSProperties; [key: string]: any }) {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <iframe
        {...props}
        title={props.title}
        style={{ ...props.style, width: "100%", height: "100%", border: "none", display: loading ? "none" : "block" }}
        onLoad={() => {
          setLoading(false);
        }}
      />
      {loading ? <AsyncComponentSpin /> : null}
    </>
  );
}
