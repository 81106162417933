import { computed, makeObservable, observable, runInAction } from "mobx";

import { appStorage } from "@/storages";

/**
 * 存放身份验证信息(token)
 */
class AuthorizeStore {
  token: string | null = null;

  get isAuthorized() {
    return !!this.token;
  }

  cancelInstall = false; // 取消正在进行的数据装载

  constructor() {
    makeObservable(this, {
      token: observable,
      isAuthorized: computed,
    });
  }

  public restore() {
    this.install(appStorage.token || undefined);
  }

  async fetch() {}

  async install(data?: string) {
    if (!data) return;
    this.token = data!;
    appStorage.token = data;

    this.fetch();
  }

  clean() {
    this.token = null;
    appStorage.token = null;
  }
}

export const authorizeStore = new AuthorizeStore();

export default authorizeStore;
