import { IPaginatedResponseBase, IResponseBase } from "../request/interfaces";
import { useConditionsQueryAntTableConfig } from "./useConditionsQueryAntTable";

useConditionsQueryAntTableConfig.query = (
  params: { pageSize: number; pageNo: number },
  query: (params: any) => Promise<IPaginatedResponseBase<any> | IResponseBase<any[]>>
) =>
  query(params).then((res) => {
    if (res.success === 0) {
      if (Array.isArray(res.data)) {
        return { list: res.data, total: res.data.length };
      }
      return res.data;
    }

    return Promise.reject();
  });
