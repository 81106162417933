export type RouterConfig = {
  id: string;
  title: string;
  path: string;
  component?: string;
  authorities?: string[];
  children?: RouterConfig[];
  meta?: {
    /**
     * 是否首页，用于同级页面默认打开
     */
    index?: boolean;
    /**
     * 可以通过该属性使用iframe
     */
    src?: string;
    /**
     * 是否允许匿名访问，既无需登录
     */
    allowAnonymous?: boolean;
    /**
     * 类型 page 为 页面，func 为功能key
     */
    type?: "page" | "func";
    /**
     * 是否在菜单中展示
     */
    showInNav?: boolean;
  };
};

export const inLayoutPages: RouterConfig[] = [
  {
    id: "6",
    title: "系统设置",
    path: "System",
    children: [
      {
        id: "6-1",
        title: "参数设置",
        path: "Settings",
        component: "Main/System/Settings/Settings",
        meta: {
          index: true,
        },
      },
      {
        id: "6-2",
        title: "轮播图列表",
        path: "Carousel",
        component: "Main/System/Carousel/Carousel",
        meta: {},
      },
      {
        id: "6-3",
        title: "菜单管理",
        path: "MenuManagement",
        component: "Main/System/MenuManagement/MenuManagement",
        meta: {},
      },
    ],
  },
];

export const publicPages: RouterConfig[] = [
  {
    id: "layout",
    title: "应用主体",
    path: "main",
    component: "Main/Layout",
    meta: {
      index: true,
      showInNav: false,
    },
  },
  {
    id: "login",
    title: "登录",
    path: "login",
    component: "Login/Login",
    meta: {
      allowAnonymous: true,
      showInNav: false,
      index: false,
    },
  },
];
