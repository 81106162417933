import { useSetState } from "ahooks";
import { useRef, useState } from "react";

import { shallowEqual } from "../object";

export type UseConditionsQueryOptions<Conditions extends {}, Parameters extends {}> = {
  /**
   * 查询函数
   */
  query: (params: Conditions & Parameters) => Promise<any>;

  /**
   * 筛选条件
   */
  conditions?: Conditions;

  /**
   * 筛选条件默认值，用于重置使用，未填写的字段使用conditions初始值
   */
  defaultConditions?: Partial<Conditions>;

  /**
   * 初始化参数
   */
  params?: Parameters;

  /**
   * 初次是否自动查询。默认为true
   */
  autoQuery?: boolean;

  /**
   * 查询条件变化后是否自动查询。默认为false
   */
  autoQueryWhenConditionsChange?: boolean;

  /**
   * 导出Excel。默认为空
   */
  // 这边unknown表示没有设置validator
  download?: (params: Conditions & Parameters) => Promise<any>;

  export: () => void;

  import: () => void;

  /**
   * 分页. false: 不分页; true: 异步分页; 'local': 本地分页;
   */
  pagination?: boolean | "local";
};

export type UseConditionsQueryWithValidatorOptions<Conditions extends {}, Parameters extends {}, FormatterData> = {
  /**
   * 查询函数
   */
  query: (params: FormatterData extends void ? Conditions & Parameters : FormatterData) => Promise<any>;

  /**
   * 执行查询/下载前的验证
   */
  validator: (params: Conditions & Parameters) => Promise<FormatterData>;

  /**
   * 筛选条件
   */
  conditions?: Conditions;

  /**
   * 筛选条件默认值，用于重置使用，未填写的字段使用conditions初始值
   */
  defaultConditions?: Partial<Conditions>;

  /**
   * 初始化参数
   */
  params?: Parameters;

  /**
   * 初次是否自动查询。默认为true
   */
  autoQuery?: boolean;

  /**
   * 查询条件变化后是否自动查询。默认为false
   */
  autoQueryWhenConditionsChange?: boolean;

  /**
   * 导出Excel。默认为空
   */
  // 这边unknown表示没有设置validator
  download?: (params: FormatterData extends void ? Conditions & Parameters : FormatterData) => Promise<any>;

  pagination?: boolean | "local";
};

export type UseConditionsQuery<Conditions extends {}, Parameters extends {}, FormatterData> = {
  conditions: Conditions;
  setConditions: (value: Partial<Conditions>) => void;
  defaultConditions: Partial<Conditions>;
  setDefaultConditions: (value: Partial<Conditions>) => void;
  params: Parameters;
  setParams: (value: Partial<Parameters>) => void;
  combineParams: FormatterData extends void | unknown ? Conditions & Parameters : FormatterData;
  query: () => void;
  fetch: () => Promise<any>;
  fetching: boolean;
  reset: (what?: "all" | "conditions" | "params") => void;
  download: (() => Promise<any>) | undefined;
  downloading: boolean;
};

export function useConditionsQuery<Conditions extends {}, Parameters extends {}>(
  options: UseConditionsQueryOptions<Conditions, Parameters>
): UseConditionsQuery<Conditions, Parameters, unknown>;
export function useConditionsQuery<Conditions extends {}, Parameters extends {}, FormatterData>(
  options:
    | UseConditionsQueryOptions<Conditions, Parameters>
    | UseConditionsQueryWithValidatorOptions<Conditions, Parameters, FormatterData>
): UseConditionsQuery<Conditions, Parameters, FormatterData> {
  const opts: Required<UseConditionsQueryWithValidatorOptions<Conditions, Parameters, FormatterData>> = {
    conditions: {} as Conditions,
    params: {} as Parameters,
    autoQuery: true,
    autoQueryWhenConditionsChange: false,
    // @ts-ignore
    validator: (params) => Promise.resolve(),
    // @ts-ignore
    download: (params) => Promise.resolve(),
    pagination: false,
    ...options,
  };

  const [fetching, setFetching] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const initConditionsRef = useRef({ ...opts.conditions });

  const [conditions, setConditions] = useSetState(opts.conditions);
  const conditionsRef = useRef<Conditions>(conditions);
  conditionsRef.current = conditions;
  const [defaultConditions, setDefaultConditions] = useSetState({ ...opts.conditions, ...opts.defaultConditions });
  const defaultConditionsRef = useRef<Conditions>(defaultConditions);
  defaultConditionsRef.current = defaultConditions;

  const [params, setParams] = useSetState(opts.params);
  const paramsRef = useRef<Parameters>(params);
  paramsRef.current = params;

  const combineParamsRef = useRef<FormatterData extends void | unknown ? Conditions & Parameters : FormatterData>({
    ...conditions,
    ...params,
  } as any);

  const autoQueryFlag = useRef(false);

  function innerSetConditions(value: Partial<Conditions>) {
    conditionsRef.current = Object.assign(conditions, value);
    setConditions(value);
    if (opts.autoQueryWhenConditionsChange && !shallowEqual(conditionsRef.current, paramsRef.current)) {
      query();
    }
  }

  function innerSetDefaultConditions(value: Partial<Conditions>) {
    defaultConditionsRef.current = Object.assign(defaultConditionsRef, value);
    setDefaultConditions(value);
  }

  function innerSetParams(value: Partial<Parameters>) {
    setParams(value);
    // 设置params将直接被合并，conditions需要查询才会
    Object.assign(paramsRef.current, value);
    // @ts-expect-error
    Object.assign(combineParamsRef.current, value);
  }

  function fetch() {
    setFetching(true);
    // @ts-ignore
    return opts.query(combineParamsRef.current!).then(
      (res) => {
        setFetching(false);
        return res;
      },
      () => {
        setFetching(false);
      }
    );
  }

  function query() {
    const _params = { ...paramsRef.current, ...conditionsRef.current };
    opts.validator(_params).then((p) => {
      // @ts-ignore
      combineParamsRef.current = p || _params;
      fetch();
    });
  }

  function download() {
    const _params = { ...paramsRef.current, ...conditionsRef.current };
    return opts.validator(_params).then((p) => {
      setDownloading(true);
      // @ts-ignore
      return opts.download(p || _params).then(
        (res) => {
          setDownloading(false);
          return res;
        },
        () => {
          setDownloading(false);
        }
      );
    });
  }

  function reset(what: "all" | "conditions" | "params" = "conditions") {
    if (what !== "params") {
      innerSetConditions(defaultConditionsRef.current);
    }
  }

  // 首次自动查询
  if (opts.autoQuery && !autoQueryFlag.current) {
    autoQueryFlag.current = true;
    query();
  }

  return {
    conditions,
    setConditions: innerSetConditions,
    defaultConditions,
    setDefaultConditions: innerSetDefaultConditions,
    params,
    setParams: innerSetParams,
    combineParams: combineParamsRef.current!,
    query,
    reset,
    fetch,
    fetching,
    download: options.download ? download : undefined,
    downloading,
  };
}
