import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { HashRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import AsyncComponent from "@/components/AsyncComponent";
import Iframe from "@/components/Iframe";
import { authorizeStore } from "@/stores/authorizeStore";
import { permissionStore } from "@/stores/permissionStore";

import { RouterConfig, publicPages } from "./routers";

const dynamicPages = import.meta.glob("./**/**.tsx");

function RouterWatcher() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authorizeStore.isAuthorized) {
      navigate("/login");
    }
  }, [location.pathname]);

  return null;
}

const PrivateComponent = observer((props) => {
  const { isAuthorized } = authorizeStore;
  const location = useLocation();

  return isAuthorized ? (
    props.component
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
      state={{ from: location.pathname }}
    />
  );
});

export default observer(() => {
  function makeRoutes(pages: RouterConfig[], level = 0) {
    return pages.reduce((pre, item, currentIndex, arr) => {
      const meta = { index: false, allowAnonymous: false, ...(item.meta || {}) };

      let element: React.ReactNode;
      if (item.component) {
        const pageLoader: () => Promise<any> =
          dynamicPages[`./${item.component}.tsx`] || dynamicPages[`./${item.component}/index.tsx`];
        element = meta.allowAnonymous ? (
          <AsyncComponent key={item.path} component={pageLoader} />
        ) : (
          <PrivateComponent key={item.path} component={<AsyncComponent key={item.path} component={pageLoader} />} />
        );
      } else if (meta.src) {
        element = <Iframe key={item.path} title={item.title} src={meta.src} />;
      } else {
        element = <Outlet />;
      }

      const { children } = item;

      let childrenNodes: JSX.Element[] = [];

      if (Array.isArray(children) && children.length) {
        const indexChild = children.find((i) => i.meta && i.meta.index) || children[0];
        childrenNodes = [
          <Route key={item.path} path={""} element={<Navigate to={indexChild.path} replace />} />,
          ...makeRoutes(children, level + 1),
        ];
      }

      if (level === 0) {
        if (item.meta?.index) {
          pre.push(<Route key="/" path="/" element={<Navigate to={item.path} replace />}></Route>);
        }
      }

      pre.push(
        <Route key={item.path} path={item.path} element={element}>
          {childrenNodes}
        </Route>
      );

      return pre;
    }, [] as any[]);
  }

  const pageRouters = makeRoutes([...permissionStore.pages]);
  return (
    <HashRouter>
      <Routes>{pageRouters}</Routes>
      <RouterWatcher />
    </HashRouter>
  );
});
