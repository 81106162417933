import { Spin } from "antd";
import React from "react";

export default function AsyncComponentSpin() {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        // backgroundImage: "linear-gradient(#00001e, #00042b)",
      }}
    >
      <Spin tip="Loading..." />
    </div>
  );
}
