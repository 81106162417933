const PRE: string = "app";

type StoreData<T> = { value: T };

function read<T = any>(key: string): T | null {
  const jsonStr = localStorage.getItem(key);
  if (!jsonStr) return null;

  return (JSON.parse(jsonStr) as StoreData<T>).value;
}

function write(key: string, value: any) {
  return localStorage.setItem(key, JSON.stringify({ value }));
}

export function storage(namespace?: string) {
  return function (constr: any) {
    return function () {
      const instance = new constr();

      const pre = namespace ? `${PRE}.${namespace}` : `${PRE}`;

      Object.getOwnPropertyNames(instance).forEach((key) => {
        if (key === "constructor") {
          return;
        }
        const defaultValue = instance[key];
        delete instance[key];

        const storageKey = `${pre}.${key}`;
        let cacheValue: any;

        let reaDed = false;
        Object.defineProperty(instance, key, {
          get() {
            if (!reaDed) {
              reaDed = true;
              cacheValue = read(storageKey) || defaultValue;
            }

            return cacheValue;
          },
          set(v) {
            cacheValue = v;
            return write(storageKey, v);
          },
        });
      });

      return instance;
    } as typeof constr;
  };
}

export default storage;
