export const globalConfig = {
  webSocketUrl: `ws://192.168.3.59:8081/api/websocket/message`,
  baseURL: process.env.REACT_APP_BASE_URL,
};

if (process.env.NODE_ENV === "production") {
  globalConfig.webSocketUrl = `${location.protocol === "http:" ? "ws" : "wss"}://${
    location.hostname
  }/api/websocket/message`;
}

if (typeof window.globalConfig === "object") {
  Object.assign(globalConfig, window.globalConfig);
}

export default globalConfig;
